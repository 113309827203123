import React from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import Pulse from "react-reveal/Pulse";

import "./AppFooter.css";

const Index = (props) => {
  return (
    <footer className="AppFooter BgPrimary">
      <Fade bottom cascade>
        <div className="Container AppFooter__Row">
          <div>
            <strong className="FooterHeading">Contact Us</strong>
            <a className="AppFooter__Link" href="tel:+233549569486">
              Tel: +233 54 956 9486
            </a>
            <a
              className="AppFooter__Link"
              style={{ textTransform: "lowercase" }}
              href="mailto:info.flinchub@gmail"
            >
              info.flinchub@gmail.com
            </a>
          </div>
          <div>
            <strong className="FooterHeading">Our Service</strong>
            <Link to="/projects?tab=graphic design" className="AppFooter__Link">
              Graphic Design
            </Link>
            <Link to="/projects?tab=websites" className="AppFooter__Link">
              Web development
            </Link>
            {/* <Link to="/projects?tab=mobile app" className="AppFooter__Link">
              Mobile App development
            </Link> */}
            <Link
              to="/projects?tab=laptop stickers"
              className="AppFooter__Link"
            >
              Advertisements
            </Link>
          </div>
          <div>
            <strong className="FooterHeading">Information</strong>
            <Link to="/about-us" className="AppFooter__Link" href="#">
              About Flinc Hub
            </Link>
            <Link to="/projects" className="AppFooter__Link" href="#">
              Our works
            </Link>
            {/* <Link to="/terms" className="AppFooter__Link" href="#">
              Terms & Conditions
            </Link> */}
          </div>
          {/* <div>
            <strong className="FooterHeading">Subscribe</strong>
            <p>Subscribe to Flinc hub's weekly tech tips via email</p>
            <input
              className="AppFooter__EmailInput"
              placeholder="Enter your active email"
            />
            <Pulse forever>
              <button className="AppFooter__SubscribeBtn">Subscribe</button>
            </Pulse>
          </div> */}
        </div>
      </Fade>
      <div className="AppFooter__CompanyName">
        &copy;Flinc Hub {new Date().getFullYear()}
      </div>
    </footer>
  );
};

export default Index;
