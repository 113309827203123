import React from "react";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";
import HeadShake from "react-reveal/HeadShake";

import "./Services.css";
import videoEditing from "./video-editing.svg";
import softwareIcon from "./software.svg";
import webDevIcon from "./webdev-icon.svg";
import mobileDevIcon from "./mobile-icon.svg";
import laptopSticker from "./laptop-sticker.svg";
import graphicDesignIcon from "./graphic-design.svg";

import Banner from "../../components/Banner";
import Container from "../../components/Container";

const Index = (props) => {
  return (
    <>
      <Banner
        heading="Our Services"
        description="We build solutions ranging from an online business presence, mobile app or software for your business, brand identity, laptop stickers and many more"
      />
      <Container className="HomeView__Container">
        <h1 className="ViewHeading">Our Services</h1>
        <div className="Services__Row">
          <div className="Services__Col">
            <div className="Services__Service">
              <Zoom>
                <img
                  className="Service__Img"
                  src={webDevIcon}
                  alt="Web development"
                />
              </Zoom>
              <HeadShake>
                <h2 className="ServiceHeading">Web Development</h2>
              </HeadShake>
              <Slide bottom>
                <p className="ServiceDescription">
                  Get a website for your business at an affordable price with
                  flexible payment options.
                </p>
              </Slide>
            </div>
          </div>
          <div className="Services__Col">
            <div className="Services__Service">
              <Zoom>
                <img
                  className="Service__Img"
                  src={graphicDesignIcon}
                  alt="Graphic Design"
                />
              </Zoom>
              <HeadShake>
                <h2 className="ServiceHeading">Graphic & Logo Design</h2>
              </HeadShake>
              <Slide bottom>
                <p className="ServiceDescription">
                  At an affordable price get a flyer or poster,business card for
                  your business.
                </p>
              </Slide>
            </div>
          </div>
          <div className="Services__Col">
            <div className="Services__Service">
              <Zoom>
                <img
                  className="Service__Img"
                  src={mobileDevIcon}
                  alt="Mobile development"
                />
              </Zoom>
              <HeadShake>
                <h2 className="ServiceHeading">Mobile Development</h2>
              </HeadShake>
              <Slide bottom>
                <p className="ServiceDescription">
                  Get a website for your business at an affordable price with
                  flexible payment options.
                </p>
              </Slide>
            </div>
          </div>
          <div className="Services__Col">
            <div className="Services__Service">
              <Zoom>
                <img
                  className="Service__Img"
                  src={videoEditing}
                  alt="video editing & ad"
                />
              </Zoom>
              <HeadShake>
                <h2 className="ServiceHeading">
                  Video editing & Advertisement
                </h2>
              </HeadShake>
              <Slide bottom>
                <p className="ServiceDescription">
                  Create your video ad for all occasions be it projects,
                  presentations, or ceremonial works.
                </p>
              </Slide>
            </div>
          </div>
          <div className="Services__Col">
            <div className="Services__Service">
              <Zoom>
                <img
                  className="Service__Img"
                  src={softwareIcon}
                  alt="software installation"
                />
              </Zoom>
              <HeadShake>
                <h2 className="ServiceHeading">Support</h2>
              </HeadShake>
              <Slide bottom>
                <p className="ServiceDescription">
                  Our team of dedicated workers are available to assist, guide
                  and support you in all IT related issues.
                </p>
              </Slide>
            </div>
          </div>
          <div className="Services__Col">
            <div className="Services__Service">
              <Zoom>
                <img
                  className="Service__Img"
                  src={laptopSticker}
                  alt="Laptop stickers"
                />
              </Zoom>
              <HeadShake>
                <h2 className="ServiceHeading">Laptop Sticker</h2>
              </HeadShake>
              <Slide bottom>
                <p className="ServiceDescription">
                  Customize and brand your laptop with various designs and
                  varieties of colors pigments.
                </p>
              </Slide>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Index;
