import React from 'react';

const textStyles = {
	lineHeight: '140%',
	margin: '1.5rem auto',
	width: '550px'
}

const Index = (props) => {
  return (
    <div style={{ margin: '5rem 0' }}>
    	<h1 className="ViewHeading" style={{ marginBottom: '2rem' }}>Terms and Conditions</h1>
    	<p style={textStyles}>
    		Lorem ipsum, dolor sit amet consectetur adipisicing elit. Architecto, quis. Nesciunt quod consequatur tempora ipsum maiores labore temporibus qui explicabo incidunt dicta quis iure molestias officiis nostrum odit omnis, amet eos sapiente? Velit perspiciatis eius debitis ea, illo soluta eaque aperiam et iste vero? Praesentium, voluptatem? Accusantium fuga, eveniet quos.   		
    	</p>
    	<p style={textStyles}>
    		Lorem ipsum, dolor sit amet consectetur adipisicing elit. Architecto, quis. Nesciunt quod consequatur tempora ipsum maiores labore temporibus qui explicabo incidunt dicta quis iure molestias officiis nostrum odit omnis, amet eos sapiente? Velit perspiciatis eius debitis ea, illo soluta eaque aperiam et iste vero? Praesentium, voluptatem? Accusantium fuga, eveniet quos.   		
    	</p>
    	<p style={textStyles}>
    		Lorem ipsum, dolor sit amet consectetur adipisicing elit. Architecto, quis. Nesciunt quod consequatur tempora ipsum maiores labore temporibus qui explicabo incidunt dicta quis iure molestias officiis nostrum odit omnis, amet eos sapiente? Velit perspiciatis eius debitis ea, illo soluta eaque aperiam et iste vero? Praesentium, voluptatem? Accusantium fuga, eveniet quos.   		
    	</p>
    	<p style={textStyles}>
    		Lorem ipsum, dolor sit amet consectetur adipisicing elit. Architecto, quis. Nesciunt quod consequatur tempora ipsum maiores labore temporibus qui explicabo incidunt dicta quis iure molestias officiis nostrum odit omnis, amet eos sapiente? Velit perspiciatis eius debitis ea, illo soluta eaque aperiam et iste vero? Praesentium, voluptatem? Accusantium fuga, eveniet quos.   		
    	</p>
    	<p style={textStyles}>
    		Lorem ipsum, dolor sit amet consectetur adipisicing elit. Architecto, quis. Nesciunt quod consequatur tempora ipsum maiores labore temporibus qui explicabo incidunt dicta quis iure molestias officiis nostrum odit omnis, amet eos sapiente? Velit perspiciatis eius debitis ea, illo soluta eaque aperiam et iste vero? Praesentium, voluptatem? Accusantium fuga, eveniet quos.   		
    	</p>
    </div>
  )
}

export default Index;