import React, { useState, useEffect } from "react";
import Zoom from "react-reveal/Zoom";

import "./Projects.css";
import Banner from "../../components/Banner";
import FilterBar from "../../components/FilterBar";
import Container from "../../components/Container";
import ProjectCard from "../../components/ProjectCard";

const Index = (props) => {
  const [projects] = useState([
    {
      title: "Fafa's Gift Flyer",
      category: "graphic design",
      imageUrl: "images/projects/graphics/fafas gift.jpg",
      previewUrl: "",
    },
    {
      title: "Groovy Sounds",
      category: "graphic design",
      imageUrl: "images/projects/graphics/groovy sounds.jpg",
      previewUrl: "",
    },
    // {
    //   title: "Cartoon Sticker",
    //   category: "laptop stickers",
    //   imageUrl: "images/projects/stickers/7.jpeg",
    //   previewUrl: "",
    // },
    // {
    //   title: "Illustrative Design",
    //   category: "laptop stickers",
    //   imageUrl: "images/projects/stickers/9.jpeg",
    //   previewUrl: "",
    // },
    {
      title: "Reach Out",
      category: "websites",
      imageUrl: "images/projects/web/reach-out.online.png",
      previewUrl: "https://reachout.online",
    },
    {
      title: "Shop Gh Electronics",
      category: "websites",
      imageUrl: "images/projects/web/shopgh.png",
      previewUrl: "https://www.shopghelectronics.com/",
    },
    {
      title: "Ezras House",
      category: "websites",
      imageUrl: "images/projects/web/ezrashouse.png",
      previewUrl: "https://ezrashouse.org/",
    },
    // {
    //   title: "Camo Design",
    //   category: "laptop stickers",
    //   imageUrl: "images/projects/stickers/10.jpeg",
    //   previewUrl: "",
    // },
    {
      title: "Groovy Sounds",
      category: "graphic design",
      imageUrl: "images/projects/graphics/groovy sounds2.jpg",
      previewUrl: "",
    },
    {
      title: "Mellow FM",
      category: "websites",
      imageUrl: "images/projects/web/orlimart.store.png",
      previewUrl: "https://orlimart.store",
    },
    // {
    //   title: "Guitar Image",
    //   category: "laptop stickers",
    //   imageUrl: "images/projects/stickers/14.jpeg",
    //   previewUrl: "",
    // },
    // {
    //   title: "Real Madrid Sticker",
    //   category: "laptop stickers",
    //   imageUrl: "images/projects/stickers/1.jpeg",
    //   previewUrl: "",
    // },
    // {
    //   title: "Game Graphic",
    //   category: "laptop stickers",
    //   imageUrl: "images/projects/stickers/3.jpeg",
    //   previewUrl: "",
    // },
    {
      title: "Mellow FM",
      category: "websites",
      imageUrl: "images/projects/web/mellowfm.live.png",
      previewUrl: "https://mellowfm.live",
    },
    {
      title: "Hair Hill",
      category: "graphic design",
      imageUrl: "images/projects/graphics/hair-hill.jpg",
      previewUrl: "https://mellowfm.live",
    },
  ]);
  const [filter, setFilter] = useState("all");
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    if (filter.toLowerCase() === "all") {
      setFilteredProjects(projects);
    } else {
      let filteredProjects = projects.filter(
        (project) => project.category.toLowerCase() === filter
      );
      setFilteredProjects(filteredProjects);
    }
  }, [filter, projects]);

  function onFilterChanged(filter) {
    setFilter(filter.toLowerCase());
  }

  return (
    <>
      <Banner
        heading="Our Works"
        description="We built and satisfied our customers with our projects"
      />
      <Container style={{ padding: "5em 0" }}>
        <h1 className="ViewHeading">Our Works</h1>
        <FilterBar
          onFilterChanged={onFilterChanged}
          filters={["All", "Websites", "Graphic Design"]}
        />
        {/*Filtered works*/}
        <Zoom cascade spy={filter || projects}>
          <div className="Projects">
            {filteredProjects.length > 0 &&
              filteredProjects.map((project, index) => (
                <ProjectCard
                  key={project.title + index}
                  title={project.title}
                  previewUrl={project.previewUrl}
                  image={process.env.PUBLIC_URL + `${project.imageUrl}`}
                  shadow
                />
              ))}
          </div>
        </Zoom>
      </Container>
    </>
  );
};

export default Index;
