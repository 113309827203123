import React from 'react';
import Header from '../../components/Header';
import AppFooter from '../../components/AppFooter';
import RouterView from '../../components/RouterView';

const ndex = (props) => {
  return (
    <>
    	<Header />
        <RouterView/>
    	<AppFooter/>
    </>
  )
}

export default ndex;