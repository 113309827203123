import React from "react";
import Zoom from "react-reveal/Zoom";
import Slide from "react-reveal/Slide";
import HeadShake from "react-reveal/HeadShake";

import "./Services.css";
import consulting from "./consulting.png";
import tryIcon from "./try.png";
import graphicsDesign from "./graphics.png"
import sms from "./sms.png"
import videoEditing from "./video-editing.png";

import Banner from "../../components/Banner";
import Container from "../../components/Container";

const Index = (props) => {
  return (
    <>
      <Banner
       heading="We deliver!"
       description="We don't just build solutions for your business, we create solutions that SELL"
      />
      <Container className="HomeView__Container">
        <h1 className="ViewHeading" style={{ marginTop: "5rem" }}>What we do</h1>
        <div className="Services__Row">
          <div className="Services__Col">
            <div className="Services__Service">
              <Zoom>
                <img
                  className="Service__Img"
                  src={tryIcon}
                  alt="Web development"
                />
              </Zoom>
              <HeadShake>
                <h2 className="ServiceHeading">Website/App for businesses</h2>
              </HeadShake>
              <Slide bottom>
                <p className="ServiceDescription">
                Increase business revenues and connect with customers through an engaging website and/or mobile app presence
                </p>
              </Slide>
            </div>
          </div>
          <div className="Services__Col">
            <div className="Services__Service">
              <Zoom>
                <img
                  className="Service__Img"
                  src={graphicsDesign}
                  alt="Graphic Design"
                />
              </Zoom>
              <HeadShake>
                <h2 className="ServiceHeading">Brand identity</h2>
              </HeadShake>
              <Slide bottom>
                <p className="ServiceDescription">
                We brand your business with social media flyers, logos and video commercials for an affordable price to give it an advantage over competitors.
                </p>
              </Slide>
            </div>
          </div>
          <div className="Services__Col">
            <div className="Services__Service">
              <Zoom>
                <img
                  className="Service__Img"
                  src={sms}
                  alt="Mobile development"
                />
              </Zoom>
              <HeadShake>
                <h2 className="ServiceHeading">Bulk SMS/Email Marketing </h2>
              </HeadShake>
              <Slide bottom>
                <p className="ServiceDescription">
                Increase response and interaction with your customers, students, parents and followers with our bulk SMS and email marketing packages
                </p>
              </Slide>
            </div>
          </div>
          <div className="Services__Col">
            <div className="Services__Service">
              <Zoom>
                <img
                  className="Service__Img"
                  src={videoEditing}
                  alt="video editing & ad"
                />
              </Zoom>
              <HeadShake>
                <h2 className="ServiceHeading">
                  Video editing & Advertisement
                </h2>
              </HeadShake>
              <Slide bottom>
                <p className="ServiceDescription">
                Get your video advertisement for all occasions including projects, presentataions, formal gatherings or a casual get-together.
                </p>
              </Slide>
            </div>
          </div>
          <div className="Services__Col">
            <div className="Services__Service">
              <Zoom>
                <img
                  className="Service__Img"
                  src={consulting}
                  alt="software installation"
                />
              </Zoom>
              <HeadShake>
                <h2 className="ServiceHeading">IT Consultation</h2>
              </HeadShake>
              <Slide bottom>
                <p className="ServiceDescription">
                Our team of qualified staff is ready to aid, counsel and support you with any difficulties relating to IT
                </p>
              </Slide>
            </div>
          </div>
          {/* <div className="Services__Col">
            <div className="Services__Service">
              <Zoom>
                <img
                  className="Service__Img"
                  src={laptopSticker}
                  alt="Laptop stickers"
                />
              </Zoom>
              <HeadShake>
                <h2 className="ServiceHeading">Laptop Sticker</h2>
              </HeadShake>
              <Slide bottom>
                <p className="ServiceDescription">
                  Customize and brand your laptop with various designs and
                  varieties of colors pigments.
                </p>
              </Slide>
            </div>
          </div> */}
        </div>
      </Container>
    </>
  );
};

export default Index;
