import React from "react";
import Slide from "react-reveal/Slide";

import "./Team.css";
import Banner from "../../components/Banner";
import TeamCard from "../../components/TeamCard";
import Container from "../../components/Container";

const members = [
  {
    name: "Christian Ansre Orison",
    position: "Team Lead",
    imageURL: "/images/team/orison.jpg",
  },
  {
    name: "Sussan Anukem",
    position: "Lead Mobile Developer",
    imageURL: "/images/team/sussan.jpg",
  },
  {
    name: "Okai Yeboah",
    position: "Senior Mobile App Developer",
    imageURL: "/images/team/okai.jpg",
  },
  {
    name: "Obitrim Paul",
    position: "Lead Web Developer",
    imageURL: "/images/team/paul.webp",
  },
  {
    name: "Zion Konlan",
    position: "Senior Web Developer",
    imageURL: "/images/team/zion.jpg",
  },
  {
    name: "Amesimeku Jewel",
    position: "UI/UX Graphics Designer",
    imageURL: "/images/team/jewel.jpg",
  },
  {
    name: "Manasseh Mborti",
    position: "UI/UX Graphic Designer",
    imageURL: "/images/team/manasseh.jpg",
  },
  {
    name: "Asamaning Redolf",
    position: "Junior Mobile App Developer",
    imageURL: "/images/team/redolf.jpeg",
  },
  {
    name: "Sabina Aglago",
    position: "IT Support",
    imageURL: "/images/team/sabina.jpg",
  },
  {
    name: "Linus",
    position: "IT Support Head",
    imageURL: "/images/team/linus.jpg",
  },
];

const Index = (props) => {
  return (
    <>
      <Banner
        heading="The Team"
        description="We are a team of enthusiastic and effecient professionals hoping to help promote businesses with our services"
      />
      {/*Information about entire team*/}
      <Container className="Team__Container">
        <section className="Team__GeneralInfo">
          <h1 className="ViewHeading">The Team</h1>
          <Slide bottom>
            <p>
              We're a team of developers, designers, thinkers, explorers. We
              approach work innovatively with fun, creativity and keep learning
              to bring the best to our clients. We create exactly the best
              content we think is fit for whatever our clients want to satisfy
              them. We design, build and ship world-class digital products for
              forward-thinking brands that connect with us.
            </p>
          </Slide>
          <Slide bottom delay={200}>
            <p>
              Everyone in the team is dedicated to serving any client with
              respect necessary. As part of our vision, we aspire to be the most
              sought I.T consultancy in creating a better everyday life by
              providing satisfactory solutions for people and business entities.
            </p>
          </Slide>
        </section>
        <section className="Team__Members">
          {members.map((member, index) => (
            <TeamCard
              key={index + member.name}
              name={member.name}
              position={member.position}
              imageURL={member.imageURL}
            />
          ))}
        </section>
      </Container>
    </>
  );
};

export default Index;
